<template>
  <div class="animated fadeIn">
    <b-container>
      <b-card>
        <loading
          :active.sync="isLoading"
          :is-full-page="false"
          :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity"
          :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"
        ></loading>
        
        <b-form @submit.stop.prevent="createLoanRequest()">
          <b-row>
            <b-col sm="6">
              <b-form-group
                id="input-group-payDescription"
                label="Утасны дугаар"
                label-for="input-username"
              >
                <money
                  id="input-username"
                  class="form-control"
                  v-model="form.username"
                  type="text"
                  required
                  placeholder="Утасны дугаар"
                  suffix=""
                  thousands=""
                  :precision="0"
                ></money>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                id="input-group-payDescription"
                label="Төлөлтийн өдөр"
                label-for="input-payDescription"
              >
                <b-form-select
                  type="number"
                  class="border-info"
                  id="input-payDescription"
                  v-model="form.repaymentDay"
                  placeholder="Төлөлтийн өдөр"
                  :options="[1, 5, 10, 15, 20, 25]"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                id="input-group-payDescription"
                label="Merchant"
                label-for="input-payDescription"
              >
                <b-form-select
                  id="input-payDescription"
                  v-model="merchantId"
                  :options="merchants"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                id="input-group-payDescription"
                label="Зээлийн төрөл"
                label-for="input-payDescription"
              >
                <b-form-select
                  id="input-payDescription"
                  v-model="loanGroup"
                  required
                  :options="this.$store.getters.loan_type_options"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="['REACTIVE_LOAN'].includes(loanGroup) && selectCustomer">
            <b-col sm="6">
              <b-form-group
                id="input-group-prevLoan"
                label="Сэргээсэн зээл"
                label-for="input-prevLoan"
              >
                <multiselect
                  v-model="re_active_loan"
                  :loading="isLoadingAccounts"
                  :options="all_accounts"
                  :searchable="true"
                  :multiple="true"
                  placeholder="Сэргээх зээл сонгох"
                  label="accountNumber"
                  :hide-selected="true"
                  :close-on-select="false"
                  track-by="id"
                >
                  <template slot="tag" slot-scope="{ option, remove }">
                    <span class="multiselect__tag">
                      <span>
                        <small>{{ option.accountNumber }}:</small> &nbsp;
                        <strong>{{ option.loanAmount + "₮" }}</strong>
                        {{ " (" + option.loanDate + ")" }}
                        <span class="badge bg-danger" v-if="option.isClosed"
                          >Хаагдсан</span
                        >
                      </span>
                      <i
                        aria-hidden="true"
                        tabindex="1"
                        @keypress.enter.prevent="remove(option)"
                        @mousedown.prevent="remove(option)"
                        class="multiselect__tag-icon"
                      ></i>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <span>
                      <small>{{ props.option.accountNumber }}:</small> &nbsp;
                      <strong class="text-danger">{{
                        props.option.loanAmount + "₮"
                      }}</strong>
                      {{ " (" + props.option.loanDate + ")" }}
                      <span class="badge bg-danger" v-if="props.option.isClosed"
                        >Хаагдсан</span
                      >
                    </span>
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            v-if="
              ['5', '6'].includes(loanGroup.substring(1,2)) &&
                selectCustomer
            "
          >
            <b-col sm="6">
              <b-form-group
                id="input-group-prevLoan"
                :label="
                    'Үлдэгдэл бууруулах зээл'
                "
                label-for="input-prevLoan"
              >
                <multiselect
                  v-model="prevLoanAccount"
                  :loading="isLoadingAccounts"
                  :options="accounts"
                  :searchable="true"
                  :multiple="false"
                  placeholder="Хаах данс сонгох"
                  label="accountNumber"
                  :close-on-select="false"
                  :custom-label="showSelectedAccount"
                  track-by="id"
                >
                  <template slot="tag" slot-scope="{ option, remove }">
                    <span class="multiselect__tag">
                      <span>
                        {{ option }}
                        <small>{{ option.accountNumber }}:</small> &nbsp;
                        <strong>{{
                          option.loanBalance + "₮/" + option.loanAmount + "₮"
                        }}</strong>
                        {{ " (" + option.loanDate + ")" }}
                      </span>
                      <i
                        aria-hidden="true"
                        tabindex="1"
                        @keypress.enter.prevent="remove(option)"
                        @mousedown.prevent="remove(option)"
                        class="multiselect__tag-icon"
                      ></i>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <span>
                      <small>{{ props.option.accountNumber }}:</small> &nbsp;
                      <strong class="text-danger">{{
                        props.option.loanBalance +
                          "₮/" +
                          props.option.loanAmount +
                          "₮"
                      }}</strong>
                      {{ " (" + props.option.loanDate + ")" }}
                    </span>
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
            <b-col sm="6" v-if="loanGroup == 'TIME_EXTEND'">
              <b-form-group
                id="input-group-prevLoan"
                label="Хүү хойшлуулах хугацаа"
                label-for="input-prevLoan"
              >
                <money
                  id="input-fee"
                  class="form-control"
                  v-model="form.term_stop"
                  type="text"
                  required
                  placeholder=""
                  suffix=" сар"
                  :precision="0"
                ></money>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group
                id="input-group-payDescription"
                label="Хүү"
                label-for="input-payDescription"
              >
                <money
                  id="input-fee"
                  class="form-control"
                  v-model="form.interest"
                  type="text"
                  required
                  placeholder=""
                  suffix=" %"
                  :precision="2"
                ></money>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                id="input-group-payDescription"
                label="Хугацаа"
                label-for="input-payDescription"
              >
                <money
                  id="input-fee"
                  class="form-control"
                  v-model="form.term"
                  type="text"
                  required
                  placeholder=""
                  suffix=" сар"
                  :precision="0"
                ></money>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col :md="loanGroup == 'P3002' || loanGroup == 'P3007' ? 6 : 12">
              <b-form-group
                id="input-group-payDescription"
                label="Дүн"
                label-for="input-payDescription"
              >
                <money
                  id="input-fee"
                  class="form-control"
                  v-model="form.amount"
                  type="text"
                  required
                  placeholder="Дүн"
                  :precision="0"
                ></money>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="loanGroup == 'P3002' || loanGroup == 'P3007'">
              <b-form-group
                id="input-group-payDescription"
                label="Хадгаламжийн дүн /сар/"
                label-for="input-payDescription"
              >
                <money
                  id="input-fee"
                  class="form-control"
                  v-model="form.savingMonthlyAmount"
                  type="text"
                  required
                  placeholder="Дүн"
                  :precision="0"
                ></money>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group
                id="input-group-payDescription"
                label="Огноо"
                label-for="input-payDescription"
              >
                <date-picker
                  id="input-transactionDate"
                  v-model="form.date"
                  :config="{ format: 'YYYY-MM-DD HH:mm:ss', useCurrent: false }"
                  required
                  :disabled="!['ADMIN', 'SUPER_ADMIN', 'SUPPORT'].includes(this.$store.getters.user.role.code)"
                  placeholder="Зээл авсан огноо"
                ></date-picker>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                id="input-group-fee"
                label="Олголтын шимтгэл /%/"
                label-for="input-payDescription"
              >
                <money
                  id="input-fee"
                  class="form-control"
                  v-model="fee"
                  type="text"
                  required
                  placeholder="Дүн"
                  suffix=" %"
                  :precision="1"
                ></money>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
            id="input-group-create-loan"
            label="Мессеж илгээх:"
            label-for="input-status"
          >
            <b-form-checkbox
              v-model="tmp_is_sms"
              :value="true"
              :unchecked-value="false"
            >
              Мессеж
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            id="input-group-payDescription"
            label="Тайлбар"
            label-for="input-payDescription"
          >
            <b-form-textarea
              id="input-payDescription"
              v-model="description"
              required
              placeholder="Тайлбар"
            ></b-form-textarea>
          </b-form-group>

          <b-card v-if="
              ['5', '6'].includes(loanGroup.substring(1,2)) &&
                selectCustomer && 
                selectCustomer.groupId == 2
            ">
            <b-form-checkbox
                v-model="form.unpaidInterest"
                :value="true"
                :unchecked-value="false"
              >
              Төлөгдөөгүй хүүг хуваан төлүүлэх
              </b-form-checkbox>  

            <div v-if="form.unpaidInterest" class="mt-3">
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="input-group-payDescription"
                    label="Төлөгдөөгүй хүү"
                    label-for="input-payDescription"
                  >
                    <money
                      id="input-fee"
                      class="form-control border-danger"
                      v-model="form.unpaidInterestAmount"
                      type="text"
                      placeholder=""
                      suffix=" ₮"
                      :precision="0"
                    ></money>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="input-group-payDescription"
                    label="Хуваах сар"
                    label-for="input-payDescription"
                  >
                    <b-select v-model="form.unpaidInterestMonth">
                      <b-form-select-option v-for="(item, key) in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="key" :value="item" v-if="item<=form.term">{{ item }} сар</b-form-select-option>

                    </b-select>
                  </b-form-group>

                  
                </b-col>
              </b-row>
            </div>
          </b-card>
          
          <hr />

          <b-form-group
            id="input-group-payDescription"
            label="Хүүг чөлөөлөх хугацаа"
            label-for="input-payDescription"
          >
            <money
              id="input-fee"
              class="form-control border-info"
              v-model="form.noInterestTerm"
              type="text"
              placeholder=""
              suffix=" сар"
              :precision="0"
            ></money>
          </b-form-group>

          <b-form-group
            id="input-group-cousername"
            label="Хамтран зээлдэгч"
            label-for="input-cousername"
          >
            <money
              id="input-cousername"
              class="form-control"
              :class="{
                'border-danger': !coCustomer,
                'border-primary': coCustomer,
              }"
              v-model="coUsername"
              type="text"
              required
              placeholder="Утасны дугаар"
              suffix=""
              thousands=""
              :precision="0"
            ></money>
            <p class="text-danger" v-if="coCustomer == false">
              Хэрэглэгчийн мэдээлэл олдсонгүй
            </p>
          </b-form-group>

          <b-form-group
            id="input-group-contract_number"
            label="Гэрээний дугаар"
            label-for="input-contract_number"
          >
            <b-row>
              <b-col sm="9">
                <b-form-input
                  id="input-contract_number"
                  class="form-control"
                  v-model="contract_number"
                  type="text"
                  required
                  placeholder="Гэрээний дугаар"
                ></b-form-input>
              </b-col>
              <b-col sm="3">
                <b-button class="pull-right" variant="primary" @click="getContractNumber"><i class="fa fa-plus" /> Гэрээний дугаар үүсгэх  </b-button>
              </b-col>
            </b-row>
       

       
            <p class="text-muted">Заавал оруулна уу</p>
          </b-form-group>

          <b-form-group 
            id="input-loan-tag"
            label="Зээлийн таг"
            label-for="input-tag">
        
              <multiselect 
              v-model="form.loan_tags" 
              tag-placeholder="Add this as new tag" 
              placeholder="Таг хайх" 
              label="name" 
              track-by="code" 
              :options="loan_tags" 
              :multiple="true" 
              :precision="0"
              :taggable="true" @tag="addTag">
            </multiselect>
          </b-form-group>

          <b-card v-if="loanGroup == 'P3001'" class="bg-gray-100">
            <h4 class="mb-0">Барьцаа</h4>
            <hr class="mt-2" />

            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Үйлдвэрлэсэн он"
                  label-for="input-factory_year"
                >
                  <money
                    id="input-factory_year"
                    class="form-control"
                    v-model="deposit.factoryYear"
                    type="text"
                    required
                    placeholder="Үйлдвэрлэсэн он"
                    suffix=""
                    thousands=""
                    :precision="0"
                  ></money>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="МУ-д орж ирсэн он"
                  label-for="input-income_year"
                >
                  <b-form-datepicker
                    id="input-income_year"
                    v-model="deposit.incomeYear"
                    placeholder="Сонгоно уу"
                    :date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }"
                    autocomplete="off"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Марк"
                  label-for="input-mark"
                >
                  <b-form-input
                    id="input-mark"
                    v-model="deposit.mark"
                    placeholder="Марк"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Улсын дугаар"
                  label-for="input-country_code"
                >
                  <b-form-input
                    id="input-country_code"
                    v-model="deposit.countryCode"
                    placeholder="Улсын дугаар"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Арлын дугаар"
                  label-for="input-registeration_code"
                >
                  <b-form-input
                    id="input-registeration_code"
                    v-model="deposit.registrationCode"
                    placeholder="Арлын дугаар"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Өнгө"
                  label-for="input-color"
                >
                  <b-form-input
                    id="input-color"
                    v-model="deposit.color"
                    placeholder="Өнгө"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-number"
                  label="Гэрчилгээний дугаар"
                  label-for="input-number"
                >
                  <b-form-input
                    id="input-number"
                    v-model="deposit.number"
                    placeholder="Гэрчилгээний дугаар"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-intended"
                  label="Зориулалт"
                  label-for="input-intended"
                >
                  <b-form-input
                    id="input-intended"
                    v-model="deposit.intended"
                    placeholder="Зориулалт"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Үнэлгээ хийсэн дүн"
                  label-for="input-evaluation_amount"
                >
                  <money
                    id="input-evaluation_amount"
                    class="form-control"
                    v-model="deposit.insuranceEvaluationAmount"
                    type="text"
                    required
                    placeholder="Үнэлгээ хийсэн дүн"
                    suffix=" ₮"
                    thousands=","
                    :precision="0"
                  ></money>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card v-if="loanGroup == 'P3002' || loanGroup == 'P3007' " class="bg-gray-100">
            <h4 class="mb-0">Барьцаа</h4>
            <hr class="mt-2" />

            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Үйл ажиллагааны чиглэл"
                  label-for="input-spec"
                >
                  <b-form-select
                    id="input-spec"
                    v-model="deposit_women.spec"
                    :options="depositSpecListWomen"
                    placeholder="Үйл ажиллагааны чиглэл"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-operatedSince"
                  label="Үйл ажиллагаа явуулж эхэлсэн он"
                  label-for="input-operatedSince"
                >
                  <money
                    id="input-factory_year"
                    class="form-control"
                    v-model="deposit_women.operatedSince"
                    type="text"
                    required
                    placeholder="Үйл ажиллагаа явуулж эхэлсэн он"
                    suffix=""
                    thousands=""
                    :precision="0"
                  ></money>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Үйл ажиллагаа"
                  label-for="input-spec"
                >
                  <b-form-input
                    id="input-spec"
                    v-model="deposit_women.operation"
                    placeholder="Үйл ажиллагаа"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-operatingLocation"
                  label="Үйл ажиллагааны байршил"
                  label-for="input-operatingLocation"
                >
                  <b-form-input
                    id="input-factory_year"
                    class="form-control"
                    v-model="deposit_women.operatingLocation"
                    type="text"
                    required
                    placeholder=""
                    suffix=""
                    thousands=""
                    :precision="0"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-operatingLocationType"
                  label="Үйл ажиллагааны байршил"
                  label-for="input-operatingLocationType"
                >
                  <b-form-select
                    id="input-operatingLocationType"
                    v-model="deposit_women.operatingLocationType"
                    placeholder="Үйл ажиллагааны байршил"
                    :options="depositLocationType"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-savingBank"
                  label="Банкны нэр"
                  label-for="input-savingBank"
                >
                  <b-form-select
                    id="input-savingBank"
                    class="form-control"
                    v-model="deposit_women.savingBank"
                    :options="depostiBankList"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-savingBankAccount"
                  label="Дансны дугаар"
                  label-for="input-savingBankAccount"
                >
                  <b-form-input
                    id="input-savingBankAccount"
                    class="form-control"
                    v-model="deposit_women.savingBankAccount"
                    type="text"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-isSingleMother"
                  label="Өрх толгойлсон эсэх"
                  label-for="input-isSingleMother"
                >
                  <b-form-select
                    id="input-isSingleMother"
                    class="form-control"
                    v-model="deposit_women.isSingleMother"
                    :options="isSingleList"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-childrenCount"
                  label="Хүүхдийн тоо"
                  label-for="input-childrenCount"
                >
                  <b-form-input
                    id="input-childrenCount"
                    class="form-control"
                    v-model="deposit_women.childrenCount"
                    type="text"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card v-if="['P3001', 'P3002', 'P4001', 'P3003', 'P3004', 'P3005', 'P3006' , 'P3007', 'P4001', 'P4002', 'P4004', 'P4003'].includes(loanGroup)" class="bg-gray-100">
            <h4 class="mb-0">Санхүүгийн мэдээлэл</h4>
            <hr class="mt-2" />
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-type"
                  label="Орлогын төрөл"
                  label-for="input-type"
                >
                  <b-select v-model="deposit_finance.incomeType" :options="income_type_list">
                    <template #first>
                    <b-form-select-option :value="null" disabled>-- Сонгоно уу --</b-form-select-option>
                  </template>
                  </b-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-ownership"
                  label="Салбар"
                  label-for="input-ownership"
                >
                <b-select v-model="deposit_finance.branch" :options="branch_list" :disabled="!deposit_finance.incomeType">
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Сонгоно уу --</b-form-select-option>
                  </template>
                </b-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <b-form-group
                  id="input-group-ownership"
                  label="Дэд салбар"
                  label-for="input-ownership"
                >
                <b-select v-model="deposit_finance.subBranch" :options="sub_branch_list" :disabled="(!deposit_finance.incomeType || !deposit_finance.branch)">
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Сонгоно уу --</b-form-select-option>
                  </template>
                </b-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-square"
                  label="Өр орлогын харьцаа"
                  label-for="input-square"
                >
                  <money
                    id="input-factory_year"
                    class="form-control"
                    v-model="deposit_finance.debtRatio"
                    type="text"
                    required
                    placeholder="Бичнэ үү"
                    suffix=""
                    thousands=""
                    :precision="2"
                  ></money>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-square"
                  label="Сарын орлого"
                  label-for="input-square"
                >
                  <money
                    id="input-factory_year"
                    class="form-control"
                    v-model="deposit_finance.incomeMonthly"
                    type="text"
                    required
                    placeholder="Бичнэ үү"
                    suffix=""
                    thousands="'"
                    :precision="0"
                  ></money>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <b-form-group
                  id="input-group-ownership"
                  label="Ипотектэй эсэх"
                  label-for="input-ownership"
                >
                <b-select v-model="deposit_finance.isIpotek" :options="ipotek_list"></b-select>
                </b-form-group>
              </b-col>
            </b-row>

          </b-card>

          <b-card v-if="loanGroup == 'P3003'" class="bg-gray-100">
            <h4 class="mb-0">Оюутаны мэдээлэл</h4>
            <hr class="mt-2" />

            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Сургууль"
                  label-for="input-spec"
                >
                  <b-form-select
                    id="input-spec"
                    v-model="deposit_student.school"
                    placeholder="Сургууль"
                    :options="studentSchool"
                  >
                    <template #first>
                      <b-form-select-option disabled selected :value="null">Сонгоно уу</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-operatedSince"
                  label="Курс"
                  label-for="input-operatedSince"
                >
                  <money
                    id="input-factory_year"
                    class="form-control"
                    v-model="deposit_student.level"
                    type="text"
                    required
                    placeholder="Курс"
                    suffix=""
                    thousands=""
                    :precision="0"
                  ></money>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-operatedSince"
                  label="Голч"
                  label-for="input-operatedSince"
                >
                  <money
                    id="input-factory_year"
                    class="form-control"
                    v-model="deposit_student.medium_score"
                    type="text"
                    required
                    placeholder="Голч"
                    suffix=""
                    thousands=""
                    :precision="1"
                  ></money>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-operatingLocation"
                  label="Мэргэжил /Салбар/"
                  label-for="input-operatingLocation"
                >
                  <b-form-select
                    id="input-factory_year"
                    class="form-control"
                    v-model="deposit_student.prof"
                    :options="studentProf"
                    required
                  >
                    <template #first>
                      <b-form-select-option disabled selected :value="null">Сонгоно уу</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-operatingLocationType"
                  label="Ажил Эрхэлдэг Эсэх"
                  label-for="input-operatingLocationType"
                >
                  <b-form-select
                    id="input-operatingLocationType"
                    v-model="deposit_student.work"
                    placeholder="Ажил Эрхэлдэг Эсэх"
                    :options="studentWork"
                  >
                    <template #first>
                      <b-form-select-option disabled selected :value="null">Сонгоно уу</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-savingBank"
                  label="Оршин Суугаа Хаяг"
                  label-for="input-savingBank"
                >
                  <b-form-input
                    id="input-savingBank"
                    class="form-control"
                    v-model="deposit_student.address"
                    type="text"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-savingBank"
                  label="Хаягийн Эзэмшлийн Байдал"
                  label-for="input-savingBank"
                >
                  <b-form-select
                    id="input-savingBank"
                    class="form-control"
                    v-model="deposit_student.address_type"
                    :options="studentAddressType"
                  >
                    <template #first>
                      <b-form-select-option disabled selected :value="null">Сонгоно уу</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-savingBank"
                  label="Тухайн Хаягт Оршин Суугаа Хугацаа"
                  label-for="input-savingBank"
                >
                  <b-form-select
                    id="input-savingBank"
                    class="form-control"
                    v-model="deposit_student.address_term"
                    :options="studentAddressTerm"
                  >
                    <template #first>
                      <b-form-select-option disabled selected :value="null">Сонгоно уу</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-savingBank"
                  label="Хөдөө Орон Нутгийн Оюутан Эсэх"
                  label-for="input-savingBank"
                >
                  <b-form-select
                    id="input-savingBank"
                    class="form-control"
                    v-model="deposit_student.is_ub"
                    :options="optionsTrueFalse"
                  >
                    <template #first>
                      <b-form-select-option disabled selected :value="null">Сонгоно уу</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-savingBank"
                  label="Хүүхэдтэй Эсэх"
                  label-for="input-savingBank"
                >
                  <b-form-select
                    id="input-savingBank"
                    class="form-control"
                    v-model="deposit_student.is_child"
                    :options="optionsTrueFalse"
                  >
                    <template #first>
                      <b-form-select-option disabled selected :value="null">Сонгоно уу</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card v-if="loanGroup == 'P3001'" class="bg-gray-100">
            <h4 class="mb-0">Даатгал</h4>
            <hr class="mt-2" />
            
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Даатгалын нэр"
                  label-for="input-insurance_name"
                >
                  <b-form-input
                    id="input-insurance_name"
                    v-model="deposit.insuranceName"
                    placeholder="Даатгалын нэр"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Даатгалын төрөл"
                  label-for="input-insurance_type"
                >
                  <b-form-input
                    id="input-insurance_type"
                    v-model="deposit.insuranceType"
                    placeholder="Даатгалын төрөл"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col> </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Эхлэх огноо"
                  label-for="input-start_date"
                >
                  <b-form-datepicker
                    id="input-start_date"
                    v-model="deposit.insuranceStartDate"
                    placeholder="Сонгоно уу"
                    :date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }"
                    locale="mn"
                    autocomplete="off"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Дуусах огноо"
                  label-for="input-end_date"
                >
                  <b-form-datepicker
                    id="input-end_date"
                    v-model="deposit.insuranceEndDate"
                    placeholder="Сонгоно уу"
                    :date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }"
                    autocomplete="off"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col> </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Үнэлгээ хийсэн дүн"
                  label-for="input-evaluation_amount"
                >
                  <money
                    id="input-evaluation_amount"
                    class="form-control"
                    v-model="deposit.insuranceEvaluationAmount"
                    type="text"
                    required
                    placeholder="Үнэлгээ хийсэн дүн"
                    suffix=" ₮"
                    thousands=","
                    :precision="0"
                  ></money>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Даатгалын хувь "
                  label-for="input-percent"
                >
                  <money
                    id="input-percent"
                    class="form-control"
                    v-model="deposit.insurancePercent"
                    type="text"
                    required
                    placeholder="Даатгалын хувь "
                    suffix=" %"
                    thousands=","
                    :precision="1"
                  ></money>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Даатгал хийлгэсэн дүн"
                  label-for="input-amount"
                >
                  <money
                    id="input-amount"
                    class="form-control"
                    v-model="deposit.insuranceAmount"
                    type="text"
                    required
                    placeholder="Даатгал хийлгэсэн дүн"
                    suffix=" ₮"
                    thousands=","
                    :precision="0"
                  ></money>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card v-if="loanGroup == 'P3006'" class="bg-gray-100">
            <h4 class="mb-0">Нэмэлт мэдээлэл</h4>
            <hr class="mt-2" />
            
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Тухайн улсад оршинг суугаа хугацаа"
                  label-for="input-insurance_name"
                >
                  <money
                    id="input-evaluation_amount"
                    class="form-control"
                    v-model="deposit_interntional.duration"
                    type="text"
                    required
                    placeholder="Тухайн улсад оршинг суугаа хугацаа"
                    suffix=" жил"
                    thousands=""
                    :precision="1"
                  ></money>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Ам бүлийн тоо"
                  label-for="input-insurance_type"
                >
                 <money
                    id="input-evaluation_amount"
                    class="form-control"
                    v-model="deposit_interntional.family_member_count"
                    type="text"
                    required
                    placeholder="Ам бүлийн тоо"
                    suffix=""
                    thousands=""
                    :precision="0"
                  ></money>
                </b-form-group>
              </b-col>
              <b-col> </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-payDescription"
                  label="Зориулалт"
                  label-for="input-evaluation_amount"
                >
                  <b-form-input
                    id="input-country_code"
                    v-model="deposit_interntional.intended"
                    placeholder="Зориулалт"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-button type="submit" variant="success" class="pull-left"
            >Хадгалах</b-button
          >

          <LoanContract
            v-if="loanGroup == 'P3001'"
            class="pull-left"
            :selectCustomer="selectCustomer"
            :coCustomer="coCustomer"
            :contract_number="contract_number"
            :amount="form.amount"
            :interest="form.interest"
            :term="form.term"
          ></LoanContract>
          <FiduciaryContract
            v-if="loanGroup == 'P3001'"
            class="pull-left"
            :selectCustomer="selectCustomer"
            :deposit="deposit"
            :contract_number="contract_number"
            :amount="form.amount"
          ></FiduciaryContract>
          <SmallBusinessLoanContract
            v-if="loanGroup == 'P3002' || loanGroup == 'P3007'"
            class="pull-left"
            :selectCustomer="selectCustomer"
            :coCustomer="coCustomer"
            :deposit_women="deposit_women"
            :contract_number="contract_number"
            :amount="form.amount"
            :interest="form.interest"
            :term="form.term"
          ></SmallBusinessLoanContract>
          <StudentLoanContract
            v-if="loanGroup == 'P3003'"
            class="pull-left"
            :selectCustomer="selectCustomer"
            :coCustomer="coCustomer"
            :contract_number="contract_number"
            :amount="form.amount"
            :interest="form.interest"
            :term="form.term"
            :deposit="deposit_student"
          ></StudentLoanContract>

          <GenerateRepaymentSchedule
            class="pull-left"
            :selectCustomer="selectCustomer"
            :coCustomer="coCustomer"
            :contract_number="contract_number"
            :repaymentDay="form.repaymentDay"
            :amount="form.amount"
            :fee="fee"
            :interest="form.interest"
            :term="form.term"
            :date="form.date"
            :noInterestTerm="form.noInterestTerm"
            :savingMonthlyAmount="form.savingMonthlyAmount"
            :unpaidInterest="form.unpaidInterest"
            :unpaidInterestAmount="form.unpaidInterestAmount"
            :unpaidInterestMonth="form.unpaidInterestMonth"
          ></GenerateRepaymentSchedule>
          <ForeignContract
            v-if="loanGroup == 'P3006'"
            class="pull-left"
            :selectCustomer="selectCustomer"
            :coCustomer="coCustomer"
            :contract_number="contract_number"
            :fee="fee"
            :amount="form.amount"
            :interest="form.interest"
            :term="form.term"
          ></ForeignContract>
          <PurchaseContract
            v-if="loanGroup == 'P4004'"
            class="pull-left"
            :selectCustomer="selectCustomer"
            :coCustomer="coCustomer"
            :contract_number="contract_number"
            :fee="fee"
            :amount="form.amount"
            :interest="form.interest"
            :term="form.term"
          ></PurchaseContract>
          <EmployeeContract
            v-if="loanGroup == 'P4002'"
            class="pull-left"
            :selectCustomer="selectCustomer"
            :coCustomer="coCustomer"
            :contract_number="contract_number"
            :fee="fee"
            :amount="form.amount"
            :interest="form.interest"
            :term="form.term"
          ></EmployeeContract>
        </b-form>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import GenerateRepaymentSchedule from "./GenerateRepaymentSchedule.vue";
import LoanContract from "./GenerateContract/LoanContract.vue";
import FiduciaryContract from "./GenerateContract/FiduciaryContract.vue";
import SmallBusinessLoanContract from "./GenerateContract/SmallBusinessLoanContract.vue";
import StudentLoanContract from "./GenerateContract/StudentLoanContract.vue";
import ForeignContract from "./GenerateContract/ForeignContract.vue";
import PurchaseContract from "./GenerateContract/PurchaseContract.vue";
import EmployeeContract from "./GenerateContract/EmployeeContract.vue";

export default {
  name: "new-loan",
  components: { GenerateRepaymentSchedule, LoanContract, FiduciaryContract, SmallBusinessLoanContract, StudentLoanContract, ForeignContract, PurchaseContract, EmployeeContract },
  data: function() {
    return {
      selectCustomer: null,
      coCustomer: null,
      coUsername: "",
      isLoading: false,
      is_sms: false,
      is_repayment_day: false,
      tmp_is_sms: false,
      fee: 0.0,
      description: "",
      loanGroup: "",
      merchantId: null,
      contract_number: "",
      unpaid_interest: 0,
      unpaid_interest_divide: false,
      form: {
        merchant: null,
        repaymentDay: null,
        username: "",
        interest: 0.0,
        term: 1,
        term_stop: 0,
        amount: 0,
        savingMonthlyAmount: 0,
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
        noInterestTerm: 0,
        interestStartDate: moment().format("YYYY-MM-DD"),
        loan_tags: [],
        unpaidInterest: false,
        unpaidInterestAmount: 0,
        unpaidInterestMonth: 1
      },
      accounts: [],
      merchants: [],
      scheduleList: [],
      loanAccountId: 0,
      prevLoanAccount: null,
      all_accounts: [],
      re_active_loan: [],
      re_loan_tags: [],
      loan_tags: [],
      deposit_student: {
        school: null,
        level: null,
        medium_score: null, 
        prof: null,
        work: null,
        address_type: null,
        address: "",
        address_term: null,
        is_ub: 0,
        is_child: 0
      },
      deposit_finance: {
        incomeType: null,
        branch: null,
        subBranch: null,
        debtRatio: 0.0,
        incomeMonthly: 0,
        isIpotek: false,
      },
      deposit_women: {
        spec: "",
        operatedSince: 0,
        operation: "",
        operatingLocation: "",
        operatingLocationType: "",
        savingBank: "",
        savingBankAccount: "",
        isSingleMother: 0,
        childrenCount: ""
      },
      deposit: {
        factoryYear: 0,
        incomeYear: null,
        mark: "",
        countryCode: "",
        registrationCode: "",
        number: "",
        intended: "",
        color: "",
        insuranceName: "",
        insuranceType: "",
        insuranceStartDate: null,
        insuranceEndDate: null,
        insuranceEvaluationAmount: 0,
        insurancePercent: 0,
        insuranceAmount: 0,
      },
      deposit_interntional: {
        duration: 0.0,
        intended: "",
        family_member_count: 0
      },
      is_deposit: false,
      no_deposit_message: "Үйлдвэрлэсэн он оруулаагүй байна.",
      depositLocationType: [
        { text: "Түрээс", value: "Түрээс" },
        { text: "Өөрийн эзэмшлийн", value: "Өөрийн эзэмшлийн" },
      ],

      is_deposit_women: false,
      no_deposit_women_message: "Үйл ажиллагааны чиглэл сонгоогүй байна.",

      is_finance: false,
      no_finance_message: "Орлогын төрөл",
      depositSpecListWomen: [
        { text: "Худалдаа", value: "Худалдаа" },
        { text: "Үйлчилгээ", value: "Үйлчилгээ" },
        { text: "Үйлдвэр", value: "Үйлдвэр" },
      ],
      studentSchool: [
        { value: "muis", text: "Монгол улсын их сургууль" },
        { value: "shutis", text: "Шинжлэх ухаан технологийн их сургууль" },
        { value: "ashius", text: "Анагаахын шинжлэх ухааны их сургууль" },
        { value: "haais", text: "Хөдөө аж ахуйн их сургууль" },
        { value: "sezis", text: "Санхүү эдийн засгийн их сургууь" },
        { value: "mubis", text: "Монгол улсын боловсролын их сургууль" },
        { value: "suis", text: "Соёл урлагийн их сургууль" },
        { value: "huis", text: "Хүмүүнлэгийн ухааны их сургууль" },
      ],
      studentProf: [
        { value: "1", text: "Хууль эрх зүй" },
        { value: "2", text: "Эдийн засаг Бизнес" },
        { value: "3", text: "Анагаах" },
        { value: "4", vatextlue: "Багш" },
        { value: "5", text: "Инженер" },
        { value: "6", text: "Хөдөө аж ахуй" },
        { value: "7", text: "Соёл урлаг" },
        { value: "8", text: "Хэвлэл мэдээлэл" },
      ],
      studentWork: [
        { value: "1", text: "Тийм" },
        { value: "2", text: "Үгүй" },
        { value: "3", text: "Заримдаа" },
      ],
      studentAddressType: [
        { value: "1", text: "Өөрийн" },
        { value: "2", text: "Эцэг эхийн" },
        { value: "3", text: "Түрээсийн" },
        { value: "4", text: "Хамаатан садангийн" },
        { value: "5", text: "Дотуур байр" },
        { value: "6", text: "Бусад" },
      ],
      studentAddressTerm: [
        { value: "1", text: "1 жил" },
        { value: "2", text: "2 жил" },
        { value: "3", text: "3 жил" },
        { value: "4", text: "4 жил" },
        { value: "5", text: "5 жил" },
        { value: "6", text: "6 жил" },
        { value: "7", text: "6с дээш жил" },
      ],
      optionsTrueFalse: [
        { value: 1, text: "Тийм" },
        { value: 0, text: "Үгүй" },
      ],
      depostiBankList: [
        { text: "Худалдаа Хөгжлийн Банк", value: "Худалдаа Хөгжлийн Банк"},
        { text: "Хаан Банк", value: "Хаан Банк"}
      ],
      isSingleList: [
        { text: "Тийм", value: 1},
        { text: "Үгүй", value: 0}
      ],
      income_type_list: [],
      branch_list: [],
      sub_branch_list: [],
      ipotek_list: [
        { value: true, text: 'Тийм' },
        { value: false, text: 'Үгүй' }
      ]
    };
  },
  watch: {
    deposit_finance: {
      handler(deposit_finance) {
        let keys = {
          incomeType: "Орлогын төрөл сонгоогүй байна.",
          branch: "Салбар сонгоогүй байна.",
          subBranch: "Дэд салбар сонгоогүй байна.",
          incomeMonthly: "Сарын орлогоо оруулаагүй байна.",
        }
        this.$data.is_finance = true;
        for (let key in keys) {
          if (!deposit_finance[key]) {
            this.$data.is_finance = false;
            this.$data.no_finance_message = keys[key];
            break;
          }
        }
      },
      deep: true
    },
    deposit_women: {
      handler(deposit_women) {
        let keys = {
          spec: "Үйл ажиллагааны чиглэл сонгоогүй байна.",
          operatedSince: "Эхэлсэн он оруулаагүй байна.",
          operation: "Үйл ажиллагаа оруулаагүй байна.",
          operatingLocation: "Үйл ажиллагааны байршил оруулаагүй байна.",
          operatingLocationType:
            "Үйл ажиллагааны байршлын төрөл сонгоогүй байна.",
          savingBank: "Банкны нэр оруулаагүй байна.",
        };
        this.$data.is_deposit_women = true;
        for (let key in keys) {
          if (!deposit_women[key]) {
            this.$data.is_deposit_women = false;
            this.$data.no_deposit_women_message = keys[key];
            break;
          }
        }
      },
      deep: true,
    },
    deposit: {
      handler(deposit) {
        let keys = {
          factoryYear: "Үйлдвэрлэсэн он оруулаагүй байна.",
          incomeYear: "Орж ирсэн огноо оруулаагүй байна.",
          mark: "Марк оруулаагүй байна.",
          countryCode: "Улсын дугаар оруулаагүй байна.",
          registrationCode: "Арлын дугаар оруулаагүй байна.",
          color: "Өнгө оруулаагүй байна.",
          insuranceEvaluationAmount: "Үнэлгээний дүн оруулаагүй байна.",
          number: "Гэрчилгээний дугаар оруулаагүй байна.",
          intended: "Зориулалт оруулаагүй байна.",
        };
        this.$data.is_deposit = true;
        for (let key in keys) {
          if (!deposit[key]) {
            this.$data.is_deposit = false;
            this.$data.no_deposit_message = keys[key];
            break;
          }
        }
      },
      deep: true,
    },
    tmp_is_sms: {
      handler(tmp_is_sms) {
        this.$data.is_sms = tmp_is_sms;
      },
      deep: true,
    },
    coUsername: {
      handler(username) {
        if ((username && username > 70000000) || username === '12345678') {
          this.getCoCustomerShort(username);
        }
      },
      deep: true,
    },
    "form.username": {
      handler(username) {
        this.$data.is_repayment_day = false;
        this.$data.form.repaymentDay = null;
        this.$data.form.username = username.toString();
        if ((username && username > 70000000) || username === '12345678') {
          this.getCustomerShort(username);
        }
      },
      deep: true,
    },
    "form.noInterestTerm": {
      handler(noInterestTerm) {
        this.interestStartDateChange();
      },
      deep: true,
    },
    "form.date": {
      handler(date) {
        this.interestStartDateChange();
      },
      deep: true,
    },
    'deposit_finance.incomeType': {
      handler(incomeType) {
        console.log(incomeType)
        if(incomeType != undefined) {
          this.$data.branch_list = this.getTypeList(incomeType)
          // this.$data.form.branch = null
          // this.$data.form.subBranch = null
        }
      },
      deep: true,
    },
    'deposit_finance.branch': {
      handler(branch) {
        if(branch != undefined) {
          this.$data.sub_branch_list = this.getTypeList(branch)
          // this.$data.form.subBranch = null
        }
      },
      deep: true,
    },
    loanGroup: {
      handler(loanGroup) {
        if (this.$data.selectCustomer) {
          if (loanGroup == "P3001") {
            this.$data.is_deposit = false;
          } else {
            this.$data.is_deposit = true;
          }
          if (loanGroup == "P3002") {
            this.$data.is_deposit_women = false;
          } else {
            this.$data.is_deposit_women = true;
          }
          this.loadLoanAccounts(this.$data.form.username);
          this.loadLoanAllAccounts(this.$data.form.username);
        }
      },
      deep: true,
    },
    selectCustomer: {
      handler(selectCustomer) {
        if (["5", "6"].includes(this.$data.loanGroup.substring(1,2))) {
          this.loadLoanAccounts(this.$data.form.username);
        }
        // if (["REACTIVE_LOAN"].includes(this.$data.loanGroup)) {
        //   this.loadLoanAllAccounts(this.$data.form.username);
        // }
      },
      deep: true,
    },
    prevLoanAccount: {
      handler(prevLoanAccount) {
        if (prevLoanAccount) {
          this.$data.form.amount = parseInt(
            prevLoanAccount.loanBalance.replace(/,/g, "")
          );

          this.$data.form.unpaidInterestAmount = parseInt(
            prevLoanAccount.interestAmount.replace(/,/g, "")
          );
        }
      },
    },
  },
  //   computed: {
  //       getLoanGroupList() { return this.$config.LOAN_GROUP_LIST }
  //   },
  created() {
    this.initData();
  },
  methods: {
    addTag(newTag) {
      console.log(newTag)
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      }
      this.loan_tags.push(tag)
      this.$data.form.loan_tags.push(tag)
    },

    interestStartDateChange: function() {
      if (this.$data.form.noInterestTerm > 0) {
        this.$data.form.interestStartDate = moment(this.$data.form.date)
          .add(this.$data.form.noInterestTerm, "month")
          .format("YYYY-MM-DD");
      }
    },
    initData: function() {
      this.$data.types = []
        this.$http
        .get(
          this.$config.API_URL + "LoanWebService/get_loan_finance_list",
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            
            if(response.body.success) {
              this.$data.types = response.body.types
              this.$data.income_type_list = this.getTypeList(null)
            } 
          },
          (response) => {
            this.isLoading = false;
          }
        );
      this.$http
        .get(this.$config.API_URL + "PurchaseWebService/get_merchant_list", {
          params: {
            page: 1,
            per_page: 0,
            orderBy: "code",
            columns: [
              "code",
              "name",
              "register_number",
              "start_date",
              "end_date",
              "create_date",
            ].join(","),
          },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.isLoading = false;
            this.$data.merchants = [];
            for (var i in response.body.data) {
              if (response.body.data[i].isActive) {
                this.$data.merchants.push({
                  text:
                    "" +
                    response.body.data[i].code +
                    " - " +
                    response.body.data[i].name,
                  value: response.body.data[i].id,
                });
              }
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
        

        // this.$http
        // .get(this.$config.API_URL + "LoanWebService/get_loan_tag_list", {
        //   params: {
        //     page: 1,
        //     per_page: 0,
        //     orderBy: "code",
        //   },
        //   headers: this.$store.getters.httpHeader,
        //   emulateJSON: true,
        // })
        axios
          .post(this.$config.NUMUR_MS_ADMIN_URL + "loan/get_loan_tag_list",
              {
                  "page": 1,
                  "per_page": 0,
                  "orderBy": "code",
              },
              {
              headers: {
                  ...this.$store.getters.httpHeader, // Include your custom headers
                  "Content-Type": "application/json", // Ensure JSON content type
              },
              }
          )
        .then(
          (response) => {
            this.isLoading = false;
            for (var i in response.data.data.list) {
              if (response.data.data.list[i].isActive) {
                  this.$data.loan_tags.push({
                      name: response.data.data.list[i].name,
                      code: response.data.data.list[i].code,
                      id: response.data.data.list[i].id
                  });
              }
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );

    },
    loadLoanAccounts: function(_username) {
      if (
        this.$data.selectCustomer &&
        ["5", "6"].includes(this.$data.loanGroup.substring(1,2))
      ) {
        this.$data.accounts = [];
        this.isLoadingAccounts = true;
        this.$http
          .get(
            this.$config.API_URL + "CustomerWebService/get_customer_accounts",
            {
              params: {
                page: 1,
                per_page: 0,
                orderDirection: "ASC",
                orderBy: "loan_date",
                search: _username,
                columns: "phone",
                loanGroup: this.$data.loanGroup
              },
              headers: this.$store.getters.httpHeader,
            }
          )
          .then(
            (response) => {
              this.isLoadingAccounts = false;
              this.accounts.push(...response.body.data);
            },
            (response) => {
              this.isLoadingAccounts = false;
            }
          );

        this.$http
          .get(this.$config.API_URL + "PurchaseWebService/get_loan_list", {
            params: {
              page: 1,
              per_page: 0,
              orderDirection: "ASC",
              orderBy: "loan_date",
              search: _username,
              columns: "phone",
              loanGroup: this.$data.loanGroup
            },
            headers: this.$store.getters.httpHeader,
          })
          .then(
            (response) => {
              this.isLoadingAccounts = false;
              this.accounts.push(...response.body.data);
            },
            (response) => {
              this.isLoadingAccounts = false;
            }
          );
      }
    },
    loadLoanAllAccounts: function(_username) {
      if (
        this.$data.selectCustomer &&
        ["REACTIVE_LOAN"].includes(this.$data.loanGroup)
      ) {
        this.$data.all_accounts = [];
        this.isLoadingAccounts = true;
        this.$http
          .get(
            this.$config.API_URL +
              "CustomerWebService/get_loan_account_history",
            {
              params: {
                page: 1,
                per_page: 0,
                orderDirection: "ASC",
                orderBy: "loan_date",
                id: this.$data.selectCustomer.id,
                search: "",
                columns: "phone",
              },
              headers: this.$store.getters.httpHeader,
            }
          )
          .then(
            (response) => {
              console.log(response);
              this.isLoadingAccounts = false;
              this.all_accounts.push(...response.body.data);
            },
            (response) => {
              this.isLoadingAccounts = false;
            }
          );
      }
    },
    getCustomerShort: function(phone) {
      this.isLoading = true;
      this.$data.selectCustomer = null;
      this.$http
        .get(
          this.$config.API_URL +
            "CustomerWebService/get_customer_short_info_by_register",
          {
            params: {
              phone: phone,
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            if (response.data) {
              this.$data.selectCustomer = response.data;
              if (response.data.repaymentDay) {
                this.$data.form.repaymentDay = response.data.repaymentDay;
                this.$data.is_repayment_day = true;
              } else {
                this.$data.form.repaymentDay = null;
                this.$data.is_repayment_day = false;
              }
            } else {
              this.showToast(
                "Анхаар",
                phone + " утасны дугаартай хэрэглэгчийн мэдээлэл байхгүй байна",
                "warning"
              );
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    getCoCustomerShort: function(phone) {
      this.isLoading = true;
      this.$http
        .get(
          this.$config.API_URL +
            "CustomerWebService/get_customer_short_info_by_register",
          {
            params: {
              phone: phone,
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            if (response.data) {
              this.$data.coCustomer = response.data;
            } else {
              this.$data.coCustomer = false;
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    checkValidate: function() {
      if(!['ADMIN', 'SUPER_ADMIN', 'SUPPORT'].includes(this.$store.getters.user.role.code)) {
        var date = moment(this.$data.form.date).format('YYYY-MM-DD')
        var now = moment().format('YYYY-MM-DD');

        if (now != date) {
          this.showToast("Анхаар", "Зээлийн өнөөдрөөс өөр өдөр үүсгэж болохгүй", "warning");
          return false;
        }
      }

      if (this.$data.loanGroup == "P3002") {
        if (!this.$data.is_deposit) {
          this.showToast(
            "Барьцаа бүргээгүй байна",
            this.$data.no_deposit_message,
            "warning"
          );
        }
        return this.$data.is_deposit;
      }
      if (this.$data.loanGroup == "P3002") {
        if (!this.$data.is_deposit_women) {
          this.showToast(
            "Санхүүгийн мэдээлэл бүргээгүй байна",
            this.$data.no_deposit_women_message,
            "warning"
          );
        }
        return this.$data.is_deposit_women;
      }
      if (['P3001', 'P3002', 'P4001', 'P3003', 'P3004', 'P3005', 'P3006', 'P4002'].includes(this.$data.loanGroup)) {
        if (!this.$data.is_finance) {
          this.showToast(
            "Барьцаа бүргээгүй байна",
            this.$data.no_finance_message,
            "warning"
          );
        }
        return this.$data.is_finance;
      }
      return true;
    },
    createLoanRequest: function() {
      if (this.checkValidate()) {
        this.isLoading = true;
        this.$http
          .post(
            this.$config.API_URL + "TestWebService/create_loan_request",
            {
              data: JSON.stringify(this.$data.form),
            },
            {
              headers: this.$store.getters.httpHeader,
              emulateJSON: true,
            }
          )
          .then(
            (response) => {
              this.isLoading = false;
              var _data = response.body;
              if (_data.success) {
                if (_data.id > 0) {
                  this.createLoan(_data.id);
                } else {
                  this.showToast(
                    "Алдаа",
                    data.success.message,
                    "danger"
                  );
                }
              } else {
                this.showToast("Алдаа", "Алдаа гарлаа", "danger");
              }
            },
            (response) => {
              this.isLoading = false;
            }
          );
      }
    },
    createLoan: function(loanRequestId) {
      console.log(this.$data.loanGroup);
      this.isLoading = true;
      let prevLoanAccountId = null;
      if (this.$data.prevLoanAccount) {
        prevLoanAccountId = this.$data.prevLoanAccount.id;
      }

      let re_active_ids = [];
      for (var i in this.$data.re_active_loan) {
        re_active_ids.push(this.$data.re_active_loan[i].id);
      }

      
      let loan_tag_ids = []
      for (var i in this.$data.form.loan_tags) {
        loan_tag_ids.push(this.$data.form.loan_tags[i].id)
      }
      console.log(loan_tag_ids)

      this.$http
        .post(
          this.$config.API_URL + "LoanWebService/create_loan",
          {
            requestId: loanRequestId,
            fee: this.$data.fee,
            is_sms: this.$data.is_sms,
            description: this.$data.description,
            loan_group: this.$data.loanGroup,
            merchant_id: this.$data.merchantId,
            coUsername: this.$data.coUsername,
            contract_number: this.$data.contract_number,
            prev_loan_account_id: prevLoanAccountId,
            re_active_ids: re_active_ids.join(","),
            loan_tag_ids: loan_tag_ids.join(","),
            deposit:
              this.$data.loanGroup == "P3001"
                ? JSON.stringify(this.$data.deposit)
                : null,
            deposit_women:
              this.$data.loanGroup == "P3002"
                ? JSON.stringify(this.$data.deposit_women)
                : null,
            deposit_student:
              this.$data.loanGroup == "P3003"
                ? JSON.stringify(this.$data.deposit_student)
                : null,
            deposit_finance:
              ['P3001', 'P3002', 'P4001', 'P3003', 'P3004', 'P3005', 'P3006', 'P4002'].includes(this.$data.loanGroup)
                ? JSON.stringify(this.$data.deposit_finance)
                : null,
            deposit_interntional:
              this.$data.loanGroup == "P3006"
                ? JSON.stringify(this.$data.deposit_interntional)
                : null,
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            var _data = response.body;
            if (_data.success) {
              this.$data.loanAccountId = _data.loanAccountId;
              this.showToast("Амжилттай", "Амжилттай хадгалагдлаа", "success");
            } else {
              this.showToast("Алдаа", "Алдаа гарлаа", "danger");
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    showSelectedAccount({ accountNumber, loanBalance, loanAmount, loanDate }) {
      return `${accountNumber} — ${loanBalance}₮/${loanAmount}₮ - ${loanDate}`;
    },
    getContractNumber: function() {
      console.log(this.loanGroup,this.$store.getters.user.id)

      this.$http
        .post(
          this.$config.API_URL + "CustomerWebService/create_contract_number",
          {
            loan_type: this.loanGroup,
            sys_user_id: this.$store.getters.user.id
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            var _data = response.body;
            if (_data.successData) {
              // this.$data.loanAccountId = _data.loanAccountId;
              this.$data.contract_number = _data.successData;
              this.showToast("Амжилттай", "Гэрээний дугаар амжилттай үүсгэлээ", "success");
            } else {
              this.showToast("Алдаа", "Алдаа гарлаа", "danger");
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    getTypeList: function(parentId) {
      let result = []
      for(var i in this.$data.types) {
        let item = this.$data.types[i]
        if(parentId) {
          if(item.parentId == parentId) {
            result.push({
              value: item.id,
              text: item.name
            })
          }
        } else {
          if(!item.hasOwnProperty("parentId")) {
            result.push({
              value: item.id,
              text: item.name
            })
          }
        }
      }
      return result
    },
  },
};
</script>
