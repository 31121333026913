var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.checkPermission('admin.customer.register-star'))?_c('RegisterStarNew',{attrs:{"customerId":_vm.customerId,"callback":_vm.reload}}):_vm._e()],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-card',[_c('mini-table',{attrs:{"url":"customer/get_star_history_new","fields":_vm.columns_star,"columns":[
          'amount',
          'percent',
          'isComplete',
          'completeDate',
          'createdDate',
        ],"customerId":parseInt(this.customerId),"orderDirection":"DESC","orderBy":"created_date","export":false,"hover":"","bordered":"","fixed":"","caption":"","hasSearch":false,"title":"Од авсан түүх","refresh":_vm.refreshState}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-card',[_c('mini-table',{attrs:{"url":"customer/get_increase_history","fields":_vm.columns_increase,"columns":[
          'amount',
          'percent',
          'isComplete',
          'completeDate',
          'createdDate',
        ],"customerId":parseInt(this.customerId),"orderDirection":"DESC","orderBy":"created_date","export":false,"hover":"","bordered":"","fixed":"","caption":"","hasSearch":false,"title":"Эрхээ нэмсэн түүх","refresh":_vm.refreshState}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }