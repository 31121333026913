<template>
  <b-row>
    <b-col>
      <div>
        <loading
          :active.sync="isLoading"
          :is-full-page="false"
          :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity"
          :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"
        ></loading>
        <div :class="{ 'mb-3': this.title != '' }">
          <b-button class="pull-right ml-3" variant="light" @click="loadData()">
            <i class="fa fa-refresh"></i>
          </b-button>
          <download-excel
            class="btn btn-primary pull-right ml-3"
            :fetch="fetchData"
            :fields="download.header"
            :before-generate="startDownload"
            :before-finish="finishDownload"
            :name="filename + '.xls'"
            v-if="this.export && items != undefined && items.length > 0"
          >
            <b-spinner small type="grow" v-show="download.loading"></b-spinner>
            <span v-show="download.loading" class="ml-1">Уншиж байна</span>
            <i class="fa fa-download" v-show="!download.loading"></i>
            <span v-show="!download.loading" class="ml-1">Excel татах</span>
          </download-excel>
          <div class="pull-right mb-3">
            <b-form-select
              v-model="query.per_page"
              :options="[5, 10, 25, 100, 250, 500]"
              v-if="items != undefined && items.length > 0"
            >
            </b-form-select>
          </div>
          <b-row v-if="this.hasSearch">
            <b-form @submit.stop.prevent="submitSearch" class="col-md-4">
              <b-input-group>
                <b-input-group-prepend is-text
                  ><i class="fa fa-search"></i
                ></b-input-group-prepend>
                <b-form-input
                  v-model="search"
                  placeholder="Хайх"
                ></b-form-input>
                <b-input-group-append
                  ><b-button variant="primary" type="submit"
                    >Хайх</b-button
                  ></b-input-group-append
                >
                <b-input-group-append
                  ><b-button variant="secondary" @click="loadData"
                    ><i class="fa fa-refresh"></i
                  ></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form>
          </b-row>
          <h4 class="card-title" v-else>{{ this.title }}</h4>
        </div>

        <div
          class="table-responsive"
          v-if="items != undefined && items.length > 0"
        >
          <b-table
            id="filter-table"
            stacked="md"
            @sort-changed="sortingChanged"
            :dark="dark"
            :hover="hover"
            :striped="striped"
            :bordered="bordered"
            :small="true"
            :fixed="false"
            responsive="sm"
            :items="items"
            :fields="captions"
            no-local-sorting
          >
            <template v-slot:cell(index)="data">
              {{ data.index + pagination.from }}
            </template>
            <template v-slot:cell(parent)="data">
              {{
                data.item.hasOwnProperty("parent") ? data.item.parent.name : ""
              }}
            </template>
            <template v-slot:cell(nicename)="data">
              {{ data.item.lastname.substr(0, 1) + "." + data.item.firstname }}
            </template>
            <template v-slot:cell(overdueDay)="data">
              <strong class="text-danger">
                {{ data.item.overdueDay }} өдөр</strong
              >
            </template>
            <template v-slot:cell(percentage)="data">
              {{ data.item.percentage.toFixed(0) }}
            </template>
            <template v-slot:cell(isClosed)="data">
              <b-badge variant="success" v-if="data.item.isClosed"
                >Тийм</b-badge
              >
              <b-badge variant="danger" v-else>Үгүй</b-badge>
            </template>
            <template v-slot:cell(isComplete)="data">
              <b-badge variant="success" v-if="data.item.isComplete"
                >Тийм</b-badge
              >
              <b-badge variant="danger" v-else>Үгүй</b-badge>
            </template>
            <template v-slot:cell(closingAmount)="data">
              <span v-if="!data.item.isClosed">{{
                data.item.closingAmount
              }}</span>
            </template>
            <template v-slot:cell(status)="data">
              <b-badge variant="success" v-if="data.item.status == 'SUCCESS'">{{
                data.item.status
              }}</b-badge>
              <b-badge
                variant="primary"
                v-else-if="data.item.status == 'NEW'"
                >{{ data.item.status }}</b-badge
              >
              <b-badge variant="danger" v-else>{{ data.item.status }}</b-badge>
            </template>
          </b-table>

          <div slot="footer" class="mb-0 mt-3">
            <nav class="pull-right" v-if="pagination.total > query.per_page">
              <b-pagination
                class="mb-0"
                :total-rows="pagination.total"
                :per-page="query.per_page"
                v-model="query.page"
              />
            </nav>
            <p class="mb-0">
              {{ pagination.total }} өгөгдлөөс
              {{ pagination.to ? pagination.from : 0 }} -
              {{ pagination.to }} өгөгдөл харагдаж байна.
            </p>
          </div>
        </div>

        <b-alert variant="warning" v-else show class="mb-0">
          Мэдээлэл байхгүй байна
        </b-alert>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import axios from "axios";
export default {
  name: "mini-table",
  components: {
    axios,
  },
  inheritAttrs: false,
  props: {
    customerId: { type: Number, default: 0 },
    url: { type: String, default: "" },
    caption: { type: String, default: "Table" },
    hover: { type: Boolean, default: false },
    striped: { type: Boolean, default: false },
    bordered: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    fields: { type: [Array, Object], default: () => [] },
    perPage: { type: Number, default: 5 },
    orderBy: { type: String, default: "" },
    orderDirection: { type: String, default: "ASC" },
    dark: { type: Boolean, default: false },
    columns: { type: [Array, Object], default: () => [] },
    export: { type: Boolean, default: true },
    hasSearch: { type: Boolean, default: true },
    title: { type: String, default: "" },
    filename: { type: String, default: "export" },
    refresh: { type: Boolean, default: false },
  },
  data: () => {
    return {
      isLoading: false,
      filterShow: false,
      query: {
        id: 0,
        page: 1,
        per_page: 5,
        orderDirection: "ASC",
        orderBy: "id",
        search: "",
        columns: "",
      },
      pagination: {
        total: 0,
        from: 0,
        to: 0,
      },
      orderDesc: false,
      items: [],
      search: "",
      download: {
        loading: false,
        header: {},
      },
    };
  },
  created: function() {
    this.$data.query.columns = this.columns.join(",");
    this.$data.query.per_page = this.perPage;
    this.$data.query.id = this.customerId;
    this.$data.query.orderDirection = this.orderDirection;
    this.$data.query.orderBy = this.orderBy;
    this.$data.download.header = {};
    for (let i in this.fields) {
      let _field = this.fields[i];
      this.$data.download.header[_field.label] = _field.key;
    }
    this.loadData();
  },
  computed: {
    captions: function() {
      return this.fields;
    },
  },
  watch: {
    query: {
      handler(query) {
        this.loadData();
      },
      deep: true,
    },
    refresh: {
      handler(refresh) {
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    loadData: function() {
      this.checkSession();
      this.isLoading = true;

      // this.$http
      //   .get(this.$config.API_URL + this.url, {
      //     params: this.$data.query,
      //     headers: this.$store.getters.httpHeader,
      //     emulateJSON: true,
      //   })
        axios
        .post(this.$config.NUMUR_MS_ADMIN_URL + this.url, 
        this.$data.query,
        {
          headers: {
            ...this.$store.getters.httpHeader, // Include your custom headers
            "Content-Type":"application/json", // Ensure JSON content type
          }
        })
        .then(
          (response) => {
            console.log("STAR");
            console.log('response',response.data);

            this.isLoading = false;
            this.$data.items = response.data.data.data;
            this.$data.pagination.total = response.data.data.total;
            this.$data.pagination.from = response.data.data.from;
            this.$data.pagination.to = response.data.data.to;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    sortingChanged(ctx) {
      let _field = "";
      for (let i in this.fields) {
        if (this.fields[i].key == ctx.sortBy) {
          this.$data.query.orderBy = this.fields[i].sort_key;
          break;
        }
      }
      this.$data.query.orderDirection = ctx.sortDesc ? "ASC" : "DESC";
    },
    submitSearch: function() {
      this.$data.query.search = this.$data.search;
    },
    async fetchData() {
      if (this.download.loading) {
        this.download.loading = true;
        // const response = await axios.get(this.$config.API_URL + this.url, {
        //   params: {
        //     page: 1,
        //     per_page: 0,
        //     orderDirection: this.$data.query.orderDirection,
        //     orderBy: this.$data.query.orderBy,
        //     search: this.$data.query.search,
        //     columns: this.$data.query.columns,
        //     id: this.$data.query.id,.query.orderDirection
        //   },
        //   headers: this.$store.getters.httpHeader,
        //   emulateJSON: true,
        // });
        axios
        .post(this.$config.NUMUR_MS_ADMIN_URL + this.url, 
        {
          page: 1,
          per_page: 0,
          orderDirection: this.$data.query.orderDirection,
          orderBy: this.$data.query.orderBy,
          search: this.$data.query.search,
          columns: this.$data.query.columns,
          id: this.$data.query.id,
        },
        {
          headers: {
            ...this.$store.getters.httpHeader, // Include your custom headers
            "Content-Type":"application/json", // Ensure JSON content type
          }
        })
        if (response.data.status == 500) {
          this.showToast(
            "Алдаа",
            "Алдаа гарсан байна. Дахин оролдоно уу",
            "danger"
          );
          this.download.loading = false;
          return [];
        }
        let _result = [];
        for (let i in response.data.data) {
          let _item = response.data.data[i];
          _item.index = parseInt(i) + 1;
          if (
            this.url == "CustomerWebService/get_loan_account_history" &&
            _item.isClosed
          ) {
            _item.closingAmount = "";
          }
          _result.push(_item);
        }
        return _result;
      }
      return [];
    },
    startDownload() {
      this.download.loading = true;
    },
    finishDownload() {
      this.download.loading = false;
    },
  },
};
</script>
